@charset "utf-8"; /* bizmerce HMH 100909 */

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");

body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 18px;
  width: 100%;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
  font-size: 12px;
}
body {
  overflow-x: hidden;
}
ul,
ol,
dl,
li,
dt,
dd {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}
form,
fieldset,
img {
  border: 0;
  vertical-align: middle;
}
address,
caption,
em {
  font-weight: normal;
  font-style: normal;
}
caption {
  display: none;
}

table {
  border-collapse: collapse;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  vertical-align: middle;
}
input[type="text"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #d2d2d2;
  font-size: 12px;
  height: 22px;
  line-height: 18px;
  padding: 0 0 0 2px;
  vertical-align: middle;
}
/* input[type="password"] { box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box; border:1px solid #d2d2d2; font-size:12px; 
					 height:22px;line-height:18px; padding:0 0 0 2px; vertical-align:middle;} */
select {
  border: 1px solid #d2d2d2;
  vertical-align: middle;
  font-size: 12px;
  height: 22px;
  line-height: 100%;
  width: 100%;
}
textarea {
  width: 100%;
  border: 1px solid #d2d2d2;
  vertical-align: middle;
  font-size: 12px;
  padding: 5px;
  line-height: 16px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
}
a:link,
a:visited,
a:active {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}

button {
  cursor: pointer;
}
/******* 전체 영역 *******/
.total_wrap {
  background: #fff;
  min-width: 1080px;
}

/******* 헤더 영역 *******/
.header_wrap {
  overflow: hidden;
}
/* 헤더 */
.header_forposition {
  height: 80px;
  line-height: 80px;
  width: 1080px;
  margin: 0 auto;
}
.header {
  height: 80px;
  line-height: 80px;
  width: 1080px;
  margin: 0 auto;
  position: absolute;
}
/* 로고 */
.header_logo {
  text-align: center; /* width:250px; */
  float: left;
}
/* 검색 */
.srch_wrap {
  overflow: hidden;
  float: left;
  margin-top: 20px;
  margin-left: 100px;
}
.srch_input {
  width: 400px;
  border: 1px solid #00305c !important;
  outline: none;
  height: 40px !important;
  float: left;
  padding-left: 10px !important;
}
.srch_btn {
  height: 40px;
  background: #00305c;
  width: 40px;
  float: left;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 20px;
}

/******* 메뉴 영역 *******/
.menu_wrap {
  border-top: 1px solid #cfcfcf;
  border-bottom: 3px solid #2c58b1;
}

/* 메뉴 */
.menu {
  height: 55px;
  line-height: 55px;
  width: 1190px;
  margin: 0 auto;
  text-align: center;
}
/* 메뉴 화살표 */
.menu_arrow_L {
  color: #aaa;
  cursor: pointer;
  float: left;
  line-height: 55px !important;
  font-size: 14px;
  font-weight: bold;
}
.menu_arrow_R {
  color: #aaa;
  cursor: pointer;
  float: right;
  line-height: 55px !important;
  font-size: 14px;
  font-weight: bold;
}
/* 움직이는 메뉴 */
.menu_box {
  width: 1100px;
  height: 55px; /* overflow:hidden; */
  position: relative;
}
/* 10개 전체 메뉴 */
.menu_box_in {
  width: 3150px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  margin: 0px;
}
/* 롤링되는 5개 메뉴 */
.menuRoll {
  height: 100%;
  margin: 0px;
  overflow: hidden;
  float: left;
}
/* 한개씩의 메뉴 */
/* .menuMain{float:left; color:#171717; font-weight:bold; font-size:16px; width:220px; text-align:center; cursor:pointer;} */
/* .menuMain02{float:left; color:#171717; font-weight:bold; font-size:16px; width:157.1px; text-align:center; cursor:pointer;} */
.menuMain02 {
  float: left;
  color: #171717;
  font-weight: bold;
  font-size: 16px;
  width: 170px;
  text-align: center;
  cursor: pointer;
}
.menuMainAll {
  float: right;
  color: #171717;
  font-weight: normal;
  font-size: 15px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.menuMainAll02 {
  float: left;
  color: #171717;
  font-weight: normal;
  font-size: 15px;
  width: 170px;
  text-align: center;
  cursor: pointer;
}
.menuMain a {
}
.menuMainAll a {
}
.menuMain a:hover {
}
.right_menu {
  float: right;
  color: #696969;
  font-size: 11px;
  cursor: pointer;
}
.M_right_menu {
  height: 40px;
  width: 40px;
  float: right;
  line-height: 44px;
  cursor: pointer;
  text-align: center;
  color: #00305c;
  border: 1px solid #00305c;
  font-size: 20px;
  margin-right: 10px;
}

/******* 콘텐츠 영역 *******/
.contents_wrap {
  margin: 0 auto;
  background: #fff;
  position: relative;
  overflow: hidden;
  width: 1130px;
  margin-top: 23px;
  min-height: 700px;
  overflow: hidden;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.contents_wrap2 {
  margin: 0 auto;
  background: #fff;
  position: relative;
  overflow: hidden;
  width: 95%;
  margin-top: 23px;
  min-height: 800px;
  overflow: hidden;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/******* 메인 서브 메뉴 영역 *******/
.main_submenu_center {
  width: 1190px;
  margin: 0 auto;
  height: 0px;
  position: relative;
  text-align: center;
}
.main_submenu_wrap {
  position: absolute;
  top: 0px;
  z-index: 4;
  width: 1190px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f9fbfc;
  border: 1px solid #00427d;
  border-top: 0px;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: none;
}
.main_submenu_single {
  width: 170px;
  border-right: 1px solid #d6d6d6;
  height: 100%;
  float: left;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.main_submenu_single02 {
  width: 170px;
  border-right: 1px solid #d6d6d6;
  text-align: left;
  vertical-align: top;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.main_submenu_single02 a {
  cursor: pointer;
}
.main_submenu_R_X {
  border-right: 0px;
}
.main_submenuTotalTT {
  background: #e8f1f9;
  display: block;
  margin-right: 15px;
  margin-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
}

/******* 콘텐츠 좌측 메뉴 영역 *******/
.leftmenu_wrap {
  position: absolute;
  right: 0px;
  z-index: 100;
  display: none;
  width: 200px;
  overflow: auto;
  border-right: 1px solid #b3b3b3;
  border-left: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #fff;
}
.leftmenu_background {
  background: #000;
  position: absolute;
  z-index: 90;
  height: 100%;
  width: 100%;
  display: none;
  opacity: 0.5;
}
.leftmenu_top {
  overflow: hidden;
  background: #2e5a99;
}
.leftmenu_close {
  float: left;
  color: #fff;
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  border-right: 1px solid #b3b3b3;
}
.leftmenu_login {
  float: left;
  color: #fff;
  padding: 10px 10px;
  font-size: 11px;
  cursor: pointer;
  margin-right: 10px;
}
/* 좌측 메뉴 타이틀 */
.leftmenu_tt {
  font-size: 15px;
  font-weight: bold;
  background: #f5f5f5;
  border-bottom: 1px solid #d5d5d5;
  color: #003b85;
  padding: 12px 10px;
}
/* 좌측 메뉴 */
.leftmenu {
  border-bottom: 1px solid #b3b3b3;
  padding-top: 5px;
  padding-bottom: 10px;
}
/* 좌측 메뉴 싱글*/
.leftmenu_single {
  font-size: 13px;
  color: #111;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-bottom: 1px solid #d5d5d5;
  margin: 0px 10px;
  letter-spacing: -0.5px;
}
.leftmenu_single_icon {
  color: #00b0ec;
  float: right;
  margin-right: 5px;
}
.leftmenu_single a {
  color: #000;
}
/* 좌측 메뉴 더보기 */
.leftmenu_more {
  font-size: 13px;
  color: #646464;
  background: #fff;
  padding: 10px 15px;
  padding-bottom: 0px;
}

/******* 서브 메뉴 영역 *******/
.submenu_wrap {
  background: #fff;
  display: none;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
  margin: 0px 10px;
}
.submenu_Oneloca01 {
  height: 1px;
  background: #fff;
}
.submenu_Oneloca02 {
  height: 1px;
  position: relative;
}
.submenu_Onewrap {
  position: absolute;
  z-index: 4;
  width: 170px;
  left: 0px;
  border: 1px solid #d6d6d6;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f9fbfc;
  text-shadow: none;
  font-weight: normal;
  display: none;
  text-align: left;
}
/* 서브 메뉴*/
.submenu {
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 13px;
  line-height: 25px;
  color: #333d46 !important;
}
.submenu:hover {
  text-decoration: underline;
}
.submenu_current {
  font-weight: bold;
} /* 서브 현재 페이지*/

/******* 콘텐츠 *******/
.contents {
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* padding: 0px 15px 10px 15px; */
  overflow: hidden;
  border: 0px;
  min-height: 700px;
  width: 795px;
  float: left;
}

.contents2 {
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; /* padding:0px 15px 10px 15px; */
  overflow: hidden;
  border: 0px;
  min-height: 700px;
  width: 1200px;
  float: left;
}

/* 타이틀 */
.contents_tt_icon {
  color: #00b0ec;
  float: left;
  height: 30px;
  line-height: 30px !important;
  margin-bottom: 10px;
}
.contents_tt {
  font-size: 22px;
  margin-left: 5px;
  float: left;
  font-weight: bold;
  color: #000;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}
/* 서브타이틀 */
.contents_subtt_icon {
  color: #057bc3;
  clear: both;
  float: left;
  height: 15px;
  line-height: 18px !important;
  margin-bottom: 5px;
}
.contents_subtt {
  font-size: 15px;
  margin-left: 5px;
  float: left;
  font-weight: bold;
  color: #000;
  height: 15px;
  line-height: 15px;
  margin-bottom: 10px;
}
/* 배너 */
.main_banner_wrap {
  border: 1px solid #dae2e5;
  cursor: pointer;
}
/* 탭형 타이틀 */
.title_tab_wrap {
  overflow: hidden;
}
.title_tab_wrap ul {
  width: 100%;
}
.title_tab_wrap ul li {
  font-size: 14px;
  letter-spacing: -1px;
  display: inline-block;
  width: 16.665%;
  float: left;
  text-align: center;
  padding: 12px 0px 14px 0px;
  background: #f1f1f1;
}
.title_tab_wrap ul li.title_tab_select {
  background: #fff;
  font-weight: bold;
}
.title_tab_wrap ul li.title_tab_select.active {
  background: #d3d3d3; /* 선택된 상태에서 배경 색을 변경 */
  font-weight: bold; /* 글씨 두껍게 유지 */
  font-size: 18px;
}
/* 퀵 보드 탭 */
.quickbbs_tab_wrap {
  margin-bottom: 10px;
  border-bottom: 1px solid #e6ebee;
  text-align: left;
  overflow: hidden;
  font-size: 14px;
}
.quickbbs_tab_wrap span {
  padding: 5px 10px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
.quickbbs_tab_select {
  border-bottom: 5px solid #2c58b1;
}
/* 대시보드 탭 */
.tab_shape_control {
  display: block;
  margin-top: 20px;
  margin-left: 25px;
  min-height: initial !important;
  border-bottom: 1px solid #e6ebee;
  width: 745px;
}
.tab_shape_control button {
  font-size: 14px;
  color: #000;
  padding: 5px 10px;
  font-family: "Noto Sans KR", sans-serif;
  min-height: 32px;
  display: inline-block;
  min-width: initial;
  max-width: initial;
}
.tab_shape_control span {
  height: 5px !important;
}
.tab_shape_control span.css-1aquho2-MuiTabs-indicator {
  background-color: #2c58b1;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}
.tab_shape_control02 {
  display: inline !important ;
  margin-top: 20px;
  margin-left: 25px;
  min-height: initial !important;
  position: relative;
  top: -35px;
  left: 180px;
}
.tab_shape_control02 button.Mui-selected {
  font-size: 14px !important;
  color: #000;
  padding: 5px 10px;
  padding-top: 7px;
  font-family: "Noto Sans KR", sans-serif;
  min-height: 30px;
  display: inline-block;
  min-width: initial;
  max-width: initial;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
}
.tab_shape_control02 span {
  height: 0px !important;
}
.react-datepicker__input-container {
  color: #2c58b1 !important;
  font-size: 14px !important;
}
.btn_position_control {
  position: relative;
  top: -93px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 0px;
}

/* 물류관리 */
.logistics_contents_wrap {
  border: 1px solid #dae2e5;
  /* margin-top: 15px; */
  min-height: 303px;
}
.logistics_data_wrap {
  padding: 20px 25px;
}
.logistics_data_wrap02 {
  margin-bottom: -70px;
}
.logistics_dash_positon {
  position: relative;
  top: -70px;
  clear: both;
}
.logistics_dash_positon02 {
  position: relative;
  top: -90px;
  clear: both;
}
.logistics_dash_div {
  width: 32.5%;
  padding: 10px 18px;
  padding-top: 12px;
  background: #f6f7f9;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-weight: bold;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  line-height: 25px;
  font-family: "Noto Sans KR", sans-serif !important;
}
.logistics_dash_tt {
  float: left;
  font-size: 16px;
  margin-top: 3px;
}
.logistics_dash_data {
  float: right;
  font-size: 30px;
  color: #134bba;
  margin-top: 3px;
}
.logistics_dash_data span {
  font-size: 12px;
  font-weight: normal;
  color: #888;
}
.lgsDashDiv_margin {
  margin-left: 9px;
}
.logistics_AD_wrap {
  background: #dff0f8;
  border-top: 4px solid #a2d4ef;
  padding: 15px 30px;
  overflow: hidden;
  margin-top: 20px;
  align-content: center;
}
.logistics_AD_wrap iframe {
  border: 0px;
}
.logistics_AD_icon01 {
  float: left;
}
.logistics_AD_tt_wrap {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
}
.logistics_AD_tt01 {
  color: #03295a;
  font-style: italic;
  font-size: 33px;
  font-weight: bold;
  letter-spacing: -2px;
}
.logistics_AD_tt02 {
  color: #2884cd;
  font-size: 27px;
  font-weight: bold;
  letter-spacing: -1px;
  margin-left: 10px;
}
.logistics_AD_tt03 {
  font-size: 27px;
  font-weight: 400;
  letter-spacing: -1px;
  display: inline-block;
  margin-top: 10px;
}
.logistics_benefit_wrap {
  background: #eff0f2;
  padding: 20px;
  overflow: hidden;
}
.logistics_benefit_box01 {
  width: 31.5%;
  float: left;
  border-radius: 15px;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.05);
}
.logistics_benefit_box02 {
  width: 31.5%;
  float: left;
  border-radius: 15px;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.05);
  margin-left: 17.5px;
}
.logistics_benefit_tt {
  font-size: 20px;
  font-weight: bold;
  background: #002b58;
  color: #ffcc01;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
}
.logistics_benefit_contentsWrap {
  background: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-top: 0px;
  text-align: center;
}
.logistics_benefit_contentsWrap ul {
  padding: 15px;
}
.logistics_benefit_contents01 {
  text-decoration: line-through;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-top: 10px;
}
.logistics_benefit_contents02 {
  margin: 5px 0px;
}
.logistics_benefit_contents02_span01 {
  background: url(../images/sale_icon.png) 0px 0px;
  width: 97px;
  height: 70px;
  color: #fff;
  font-size: 20px;
  display: inline-block;
}
.logistics_benefit_contents02_span02 {
  padding-top: 10px;
  display: inline-block;
}
.logistics_benefit_contents03 {
  color: #d80c0d;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -1px;
  padding: 10px 0px;
}
.logistics_benefit_contents04 {
  padding: 15px 0px;
  padding-top: 5px;
  border-bottom: 1px solid #e0e0e0;
}
.logistics_benefit_contents04_span {
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  padding-left: 5px;
}
.logistics_benefit_contents05 {
  padding: 15px 0px;
  border-bottom: 1px solid #e0e0e0;
}
.logistics_benefit_contents06 {
  padding: 15px 0px;
}
.logistics_benefit_contents07 {
  padding: 10px;
  padding-top: 0px;
  border-bottom: 1px solid #e0e0e0;
}
.logistics_benefit_contents07_span01 {
  font-size: 15px;
  font-weight: bold;
  vertical-align: middle;
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.logistics_benefit_contents07_span02 {
  font-size: 20px;
  font-weight: bolder;
  vertical-align: middle;
  color: #0c4dab;
  font-size: 40px;
  padding-left: 20px;
}
.logistics_benefit_contents07_span02 span {
  font-size: 12px;
  color: #888888;
}
.logistics_benefit_contents08 {
  padding: 10px;
}
.logistics_benefit_contents09 {
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: bold;
  margin-top: 5px;
}

.logistics_dash_TotalWrap {
  background: #f1f2f2;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  margin-top: 17px;
  padding: 17px;
  font-family: "Noto Sans KR", sans-serif !important;
}
.tabContents10,
.tabContents11,
.tabContents12 {
  margin-top: 20px;
}
.logistics_dash_leftWrap {
  float: left;
  width: 230px;
}
.logistics_dash_rightWrap {
  float: right;
  width: 465px;
}
.logistics_dash_loginWrap {
  padding: 8px 10px;
  font-weight: bold;
  border: 1px solid #e2e4e5;
  background: #fff;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  line-height: 25px;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.03);
}
.logistics_dash_logintt {
  font-size: 16px;
  margin-top: 3px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 65px;
}
.logistics_dash_logindata {
  font-size: 30px;
  color: #134bba;
  margin-top: 3px;
  display: inline-block;
  position: absolute;
  top: 11px;
  right: 10px;
}
.logistics_dash_logindata span {
  font-size: 12px;
  font-weight: normal;
  color: #888;
}
.logistics_dash_loginTable {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.logistics_dash_loginTable tr {
  border-bottom: 1px solid #d7d9da;
}
/* .logistics_dash_loginTable tr:last-child {border-bottom: 0px;} */
.logistics_dash_loginTable tr th {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
  background: #a3a3a3;
  color: #fff;
}
.logistics_dash_loginTable tr td {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
}
.logistics_dash_loginTable tr td:nth-child(3n) {
  color: #c5c6c6;
  font-size: 10px;
}
.logistics_dash_loginTable tr td:nth-child(5n) {
  color: #6f6f6f;
}
.logistics_dash_loginTable02 {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.logistics_dash_loginTable02 tr {
  border-bottom: 1px solid #d7d9da;
}
/* .logistics_dash_loginTable tr:last-child {border-bottom: 0px;} */
.logistics_dash_loginTable02 tr th {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
  background: #a3a3a3;
  color: #fff;
}
.logistics_dash_loginTable02 tr td {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
}
.logistics_dash_loginTable02 tr td:nth-child(4n) {
  color: #c5c6c6;
  font-size: 10px;
}
.logistics_dash_loginTable02 tr td:nth-child(6n) {
  color: #6f6f6f;
}
.logistics_dash_loginTable03 {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.logistics_dash_loginTable03 tr {
  border-bottom: 1px solid #d7d9da;
}
/* .logistics_dash_loginTable tr:last-child {border-bottom: 0px;} */
.logistics_dash_loginTable03 tr th {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
  background: #a3a3a3;
  color: #fff;
}
.logistics_dash_loginTable03 tr td {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
}
.logistics_dash_loginTable03 tr td:nth-child(2n) {
  color: #c5c6c6;
  font-size: 10px;
}
.logistics_dash_loginTable03 tr td:nth-child(4n) {
  color: #6f6f6f;
  font-size: 14px;
}
.logistics_dash_loginTable03 tr td:nth-child(5n) {
  color: #6f6f6f;
}
.logistics_dash_loginTable04 {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.logistics_dash_loginTable04 tr {
  border-bottom: 1px solid #d7d9da;
}
/* .logistics_dash_loginTable tr:last-child {border-bottom: 0px;} */
.logistics_dash_loginTable04 tr th {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
  background: #a3a3a3;
  color: #fff;
}
.logistics_dash_loginTable04 tr td {
  font-size: 14px;
  padding: 8px 0px;
  line-height: 16px;
}
.logistics_dash_FontWeight {
  font-weight: normal;
}
.logistics_Link_btn01 {
  background: #565656;
  padding: 1px 5px;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif !important;
}
.logistics_Link_btn02 {
  background: #2c58b1;
  padding: 1px 5px;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif !important;
}
.logistics_Link_btn03 {
  background: #fa5015;
  padding: 1px 5px;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif !important;
}

/* 중고거래, 입주기업, 제품소개  */
.quickbbs_wrap {
  padding: 20px 25px;
}
.quickbbs_thumbnail_wrap {
  text-align: center;
}
.quickbbs_thumbnail {
  display: inline-block;
  margin-left: 25px;
  margin-top: 10px;
}
.quickbbs_thumbnail_01margin {
  margin-left: 25px;
  margin-bottom: 10px;
}
.quickbbs_thumbnail_01margin .MuiGrid-container {
  width: 766px;
}
.quickbbs_thumbnail_imgWrap {
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  width: 165px;
  height: 110px;
}
.quickbbs_thumbnail_txtWrap {
  margin-top: 5px;
}
.quickbbs_thumbnail_new {
  font-size: 9px;
  background: #2c58b1;
  border-radius: 2px;
  padding: 0px 3px;
  color: #fff;
  font-weight: bold;
}
.quickbbs_thumbnail_txt {
  font-weight: bold;
  width: 150px;
  display: inline-block;
  text-align: left;
  margin-left: 3px;
}
.quickbbs_thumbnail_data {
  color: #757575;
  text-align: left;
}
.quickbbs_dadanWrap01 {
  width: 360px;
  display: inline-block;
  position: relative;
  top: 0px;
}
/* .quickbbs_dadanWrap01{width:100%; display:inline-block; height:100px;} */
.quickbbs_dadanWrap02 {
  width: 360px;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 0px;
  right: 0px;
}
.quickbbs_list {
  height: 125px;
  display: block;
}
.quickbbs_list li {
  list-style: disc inside;
  margin-bottom: 3px;
  cursor: pointer;
}
.quickbbs_list_new {
  margin-left: -10px;
  margin-right: 5px;
  font-size: 9px;
  background: #2c58b1;
  border-radius: 2px;
  padding: 0px 3px;
  color: #fff;
  font-weight: bold;
}
.quickbbs_list_txt {
  font-size: 14px;
  display: inline-block;
  width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
.quickbbs_list_txt02 {
  font-size: 14px;
  display: inline-block;
  width: 253px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
.quickbbs_list_data {
  display: inline-block;
  color: #757575;
}
/* 우측 영역 */
.right_wrap {
  width: 305px;
  float: left;
  margin-left: 30px;
}
/* 로그인 영역 */
.userLoginPop {
  position: absolute;
  top: 65px;
  right: 0px;
  z-index: 200;
  width: 305px;
  line-height: normal;
  padding: 10px;
  border-radius: 5px;
  background: #eaeaea;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.05);
  display: none;
}
.loginSection_wrap {
  background: #f8f9fb;
  border: 1px solid #dae2e5;
}
.loginSection {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px; /* border-bottom: 1px solid #dae2e5; */
}
.loginBox {
  width: 200px;
  overflow: hidden;
  float: left;
}
.loginBox_idInput {
  width: 100%;
  border-bottom: 0px !important;
  padding-left: 5px !important;
  box-sizing: border-box !important;
}
.loginBox_pwInput {
  width: 100%;
  padding-left: 5px !important;
  box-sizing: border-box !important;
  border: 1px solid #d2d2d2;
  font-size: 12px;
  height: 22px;
  line-height: 18px;
  padding: 0 0 0 2px;
  vertical-align: middle;
}
.loginBtn {
  height: 44px;
  line-height: 44px;
  float: right;
  background: #2c58b1;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: -1px;
  font-weight: bold;
  padding: 0px 10px;
  font-size: 14px;
}
.login_etc_wrap {
  display: inline-block;
  padding-top: 5px;
}
.login_etc_autologinTxt {
  color: #7d7d7d;
  cursor: pointer;
}
.login_etc_autologinInput {
  position: relative;
  top: 2px;
}
.login_etc_txtDiv {
  color: #bfc0c2;
  padding: 0px 5px;
}
.login_etc_idpwSrch {
  color: #7d7d7d;
  cursor: pointer;
}
.login_etc_join {
  font-weight: bold;
}
.login_mailIcon {
  position: relative;
  top: -1px;
}
.socialLogin_box {
  padding: 7px 20px;
  color: #222;
  text-align: center;
}
.socialLogin_box div {
  padding: 4px 20px;
  background: #eee;
  display: inline-block;
}
.socialLogin_box span {
  margin-left: 10px;
  cursor: pointer;
}
.socialLogin_box span img {
  width: 25px;
  height: 25px;
}

/* 비밀번호 찾기 영역 */
.pwSrch_Wrap {
  overflow: hidden;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  width: 300px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #d2d4d5;
}
.pwSrchTt_Wrap04 {
  font-size: 12px;
  display: block;
  position: relative;
  top: 2px;
  color: #8b8b8b;
}

/* .socialLogin_katalk{background:#f6e60c; border:0px; border-radius:5px; width:110px; vertical-align:middle; padding:5px; padding-left:8px; margin-left: 5px; }
.socialLogin_katalk img{float:left;}
.socialLogin_katalk_txt{color:#3b1d1f;  font-weight:bold; text-align:center; border-left:1px solid #e3d30c; display:inline-block; padding-left:5px; letter-spacing:-1px; font-size:11px; line-height:11px; float:left; margin-left:5px;}
.socialLogin_naver{background:#1fc616; border:0px; border-radius:5px; width:110px; vertical-align:middle; padding:5px; padding-left:8px; margin-left: 5px;}
.socialLogin_naver img{float:left;} */
.socialLogin_google {
  border: 1px solid #dae2e5;
  background: #fff;
  display: inline-block;
  border-radius: 2px;
}
.socialLogin_google img {
  width: 25px !important;
}
.socialLogin_naver_txt {
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-left: 1px solid #1cb413;
  display: inline-block;
  padding-left: 5px;
  letter-spacing: -1px;
  font-size: 11px;
  line-height: 11px;
  float: left;
  margin-left: 5px;
}
.user_infoBox {
  width: 100%;
  height: 44px;
  display: inline-block;
  padding: 10px 0px;
  font-size: 14px;
}
.userName_wrap {
  font-size: 14px;
  display: block;
}
.headerUser_nm {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.headerUser_nim {
  position: relative;
  top: 3px;
}
.user_id {
  font-size: 14px;
  font-weight: bold;
}
.user_infochange {
  color: #7d7d7d;
  font-size: 14px;
}
.user_corp {
  font-size: 14px;
  color: #a0a0a1;
  display: block;
}
.logoutBtn {
  height: 44px;
  line-height: 44px;
  float: right;
  display: inline-block;
  background: #ccd0d4;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: -1px;
  font-weight: bold;
  padding: 0px 10px;
  font-size: 14px;
  margin-top: 10px;
}
.logoutBtn02 {
  height: 30px;
  width: 95%;
  line-height: 30px;
  display: inline-block;
  background: #ccd0d4;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: -1px;
  font-weight: bold;
  font-size: 14px;
}
.user_alarmWrap01 {
  width: 25%;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-right: 1px solid #dae2e5;
  box-sizing: border-box;
}
.user_alarmWrap02 {
  width: 25%;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  text-align: center;
  box-sizing: border-box;
}
.user_alarmWrap03 {
  width: 100%;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  text-align: center;
  box-sizing: border-box;
}
.user_alarmWrap_tt {
  font-size: 14px;
  font-weight: bold;
}
.user_alarmWrap_count {
  font-size: 14px;
  font-weight: bold;
  color: #2c58b1;
  margin-left: 3px;
}
/* 얼라이언스, 스마트물류정보시스템 링크 */
.join_Link_section {
  background: #f8f9fb;
  border: 1px solid #dae2e5;
  margin-top: 15px;
  padding: 11px;
}
.join_Link_btn01 {
  background: #22abcd;
  padding: 5px;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}
.join_Link_btn02 {
  background: #fff;
  padding: 5px;
  width: 100%;
  border: 1px solid #22abcd;
  border-radius: 5px;
  color: #159ab9;
  font-weight: bold;
  font-size: 14px;
}
/* 우측 배너 */
.mainright_banner {
  border: 1px solid #dae2e5;
  margin-top: 15px;
}
/* 금일 항공 스케줄 */
.plane_schedule {
  border: 1px solid #dae2e5;
  margin-top: 15px;
}
.plane_schedule_tt {
  background: #f8f9fb;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 13px;
}
.plane_schedule_tableWrap {
  overflow: hidden;
  padding: 5px;
}
.plane_schedule_table {
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.plane_schedule_table th {
  color: #636363;
  font-weight: normal;
}
.plane_schedule_table > tbody > tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}
.plane_schedule_table > tbody > tr:nth-of-type(even) {
  background-color: #eeeeee;
}
.plane_schedule_table_place {
  text-align: left;
}
.plane_schedule_table img {
  margin-top: -3px;
  margin-right: 5px;
}
/* 구축사례  */
.slp_reference_tt {
  background: url(../images/bbsQuick_ttBG.png);
  width: 303px;
  height: 92px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.5);
  line-height: 25px;
  vertical-align: middle;
  display: table-cell;
}
.slp_reference_listWrap {
  margin-left: 10px;
  padding: 10px 0px;
}
.slp_reference_listWrap li {
  list-style: disc inside;
  margin-bottom: 3px;
  cursor: pointer;
}
.slp_reference_listWrap_new {
  margin-left: -10px;
  font-size: 9px;
  background: #2c58b1;
  border-radius: 2px;
  padding: 0px 3px;
  color: #fff;
  font-weight: bold;
}
.slp_reference_listWrap_nm {
  font-weight: bold;
  margin-left: 3px;
}
/* 사이트 링크  */
.Link_box {
  border: 1px solid #dae2e5;
  margin-top: 15px;
  min-height: 65px;
  line-height: 60px;
  text-align: center;
}
.Link_box img {
  width: 240px;
}

/*조회 테이블형 DIV*/
.srch_div_allWrap {
  border-top: 2px solid #2e5a99;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
}
.srch_div_top {
  background: #f1f2f6;
  border-bottom: 1px solid #cdcdcd;
  overflow: hidden;
}
.srch_div_tt {
  padding: 10px 5px;
  float: left;
  font-weight: bold;
}
.srch_div_allopen {
  padding: 10px 5px;
  float: right;
  font-weight: bold;
}
.srch_div_one {
  background: #fff;
  border-bottom: 1px solid #cdcdcd;
  overflow: hidden;
}
.srch_div_one02 {
  background: #fff;
  border-bottom: 1px solid #cdcdcd;
  overflow: hidden;
  display: none;
}
.srch_div_oneTT {
  padding: 10px 5px;
  float: left;
  background: #fff;
  width: 12%;
}
.srch_div_oneChoi {
  padding: 8px 5px;
  float: left;
  background: #fff;
  width: 75%;
  overflow: hidden;
}
.srch_div_oneChk_Wrap {
  height: 18px;
  overflow: hidden;
  float: left;
  width: 80%;
}
.srch_div_oneLabel {
  width: 120px;
  display: block;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.srch_div_oneAdd_Wrap {
  overflow: hidden;
  float: right;
  background: #0fc;
  width: 20%;
  position: relative;
}
.srch_div_oneAdd_input {
  overflow: hidden;
  margin-right: 28px;
}
.srch_div_oneOpen {
  padding: 10px 5px;
  float: right;
  background: #fff;
}
.srch_div_oneSrch {
  padding: 8px 5px;
  background: #fafafa;
  overflow: hidden;
}
/*조회 테이블*/
.srchTable {
  width: 100%;
  border-top: 2px solid #2e5a99;
  font-family: "Noto Sans KR", sans-serif !important;
}
.srchTable th {
  font-size: 14px;
  background: #f1f2f6;
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
  padding: 5px;
  text-align: left;
  line-height: 30px;
  box-sizing: border-box;
}
.srchTable td {
  font-size: 14px;
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
  padding: 5px;
  line-height: 30px;
  box-sizing: border-box;
}
.srchTable_pad01 {
  padding-left: 30px !important;
}
.srchTable_bakcol01 {
  background: #fafafa !important;
}
.srchTable01 {
  width: 100%;
  border-top: 2px solid #2e5a99;
  height: 343px;
}
.srchTable01 th {
  font-size: 14px;
  background: #f1f2f6;
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
  padding: 5px;
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
}
.srchTable01 td {
  font-size: 14px;
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
}

/*상품 리스트 헤더 */
.product_listHead {
  border-top: 2px solid #2e5a99;
  overflow: hidden;
  padding: 5px;
  border-bottom: 1px solid #cdcdcd;
}
.product_ ot {
  float: left;
  font-weight: bold;
  font-size: 14px;
}
.product_listSrchBox {
  overflow: hidden;
  float: right;
}
.product_SrchBox_in {
  float: left;
  margin-left: 10px;
}
.product_listview_icon {
  color: #e1e1e1;
  font-size: 24px !important;
  cursor: pointer;
}
.product_listSelect {
  float: left;
  margin-left: 10px;
}
/*상품 리스트 + 비교 리스트*/
.product_list_total {
  overflow: hidden;
}
.product_list_wrap {
  padding: 20px 10px;
  border-bottom: 1px solid #cdcdcd;
  overflow: hidden;
}
.product_list_wrap02 {
  padding: 10px;
  border-bottom: 1px solid #cdcdcd;
  border-top: 2px solid #2e5a99;
  overflow: hidden;
  overflow-x: auto;
  height: 145px;
}
.product_list_inWidSize {
  float: left;
  padding-right: 15px;
  overflow: hidden;
  height: 141px;
}
.product_list_wrap03 {
  padding: 10px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border-bottom: 1px solid #cdcdcd;
}
.product_list_wrap04 {
  padding: 3px;
  overflow: hidden;
  text-align: right;
  margin-top: 10px;
}
.product_list_chkWrap {
  float: left;
  height: 138px;
  line-height: 138px;
}
.product_list_imgWrap {
  float: left;
  width: 130px;
  height: 130px;
  padding: 3px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin-left: 5px;
}
.product_list_imgWrap02 {
  float: left;
  height: 110px;
  padding: 3px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin-left: 15px;
}
.product_list_imgSize {
  width: auto;
  height: auto;
  max-width: 130px;
  max-height: 130px;
}
.product_list_imgSize02 {
  width: auto;
  height: auto;
  max-width: 90px;
  max-height: 90px;
}
.product_list_dcWrap {
  overflow: hidden;
  height: 138px;
  margin-left: 165px;
}
.product_list_dchead {
  padding: 15px 10px;
  padding-top: 10px;
  overflow: hidden;
}
.product_list_dcTT {
  font-weight: bold;
  font-size: 22px;
  float: left;
}
.product_list_makr {
  float: right;
  font-weight: bold;
}
.product_list_dc {
  white-space: normal;
  padding-left: 12px;
  padding-right: 12px;
  height: 73px;
}
.product_list_rgsde {
  padding-left: 12px;
  padding-right: 12px;
}
.product_list_imgTT {
  text-align: center;
  max-width: 86px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*상품 액자형 */
#product_frame_view {
  display: none;
}
.product_frame_wrap {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  margin-left: 18px;
}
.product_frame_imgWrap {
  width: 170px;
  height: 170px;
  padding: 3px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.product_frame_imgSize {
  width: auto;
  height: auto;
  max-width: 170px;
  max-height: 170px;
}
.product_frame_TTwrap {
  overflow: hidden;
}
.product_frame_chkbox {
  margin-top: 5px;
  float: left;
  vertical-align: middle;
  vertical-align: -2px;
  margin-left: 5px;
}
.product_frame_TT {
  font-weight: bold;
  font-size: 13px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 155px;
  margin-top: 3px;
  margin-left: 5px;
}
.product_frame_makr {
  margin-top: 10px;
  margin-left: 5px;
}
.product_frame_rgsde {
  margin-left: 5px;
}
.product_frame_dc {
  overflow: hidden;
  margin-top: 5px;
  margin-left: 5px;
}
.product_frame_answer01 {
  float: left;
  width: 46%;
  letter-spacing: -1px;
}
.product_frame_seline {
  float: left;
  width: 12%;
  color: #ccc;
}
.product_frame_answer02 {
  float: left;
  width: 42%;
  letter-spacing: -0.5px;
}
.product_frame_fontstyle01 {
  color: #00f;
  font-weight: bold;
}
.product_frame_fontstyle02 {
  color: #ff0000;
  font-weight: bold;
}

/*기기비교*/
.product_compare_tt {
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0px;
}
.product_compare_icon {
  margin-right: 5px;
  color: #2e5a99;
}
.product_compare_wrap {
  overflow: hidden;
}
.product_compare_thead {
  overflow: hidden;
  width: 85px;
  float: left;
}
.product_compare_tbody {
  overflow: hidden;
  width: 965px;
  float: left;
  overflow-x: auto;
}
#compare_body td {
  border-right: 1px solid #cdcdcd;
  width: 390px;
}
.product_compare_imgWrap {
  width: 200px;
  height: 200px;
  padding: 3px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin: 0 auto;
}
.product_compare_imgSize {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}

/*상세페이지*/
.view_goods_wrap {
  padding: 10px 0px;
  overflow: hidden;
  border-top: 2px solid #2e5a99;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 20px;
}
.view_goodsImg {
  overflow: hidden;
  border: 1px solid #e5e5e5;
  padding: 5px 0px;
  width: 60%;
  float: left;
}
.view_imgWrap {
  height: 170px;
  padding: 3px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  line-height: 170px;
}
.view_imgSize {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  display: none;
}
.view_tumWrap {
  width: 30%;
  height: 100px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  line-height: 100px;
  float: left;
  margin-left: 2%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.view_tumImgSize {
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;
}
.view_dc_wrap {
  overflow: hidden;
  width: 37%;
  float: right;
  padding: 0px 5px;
}
.view_tt {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 50px;
  line-height: 25px;
  color: #2e5a99;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.star_section_wrap {
  overflow: hidden;
  margin-bottom: 10px;
}
.star_section_wrap02 {
  overflow: hidden;
  margin-bottom: 0px;
  margin-top: 10px;
}
.star_section_tt01 {
  float: left;
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
  padding-right: 15px;
}
.star_section_tt02 {
  float: left;
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
}
/* .star_out{	padding-right: 0px;	padding-left: 0px;	background: url(../images/icon_star2.gif) 0px 0px;	float: left;	padding-bottom: 0px;	margin: 0px;	width: 90px;	padding-top: 0px;	height: 18px;} */
/* .star_in{width: 0%; padding-right:0px;	padding-left:0px;	background: url(../images/icon_star.gif) 0px 0px;	padding-bottom: 0px;	margin: 0px;	padding-top: 0px;	height: 18px;} */
.star_section_score {
  float: left;
  font-size: 15px;
  margin-left: 5px;
}
.star10pro {
  width: 10% !important;
} /* 별 반개 */
.star20pro {
  width: 20% !important;
} /* 별 한개 */
.star30pro {
  width: 30% !important;
} /* 별 한개 반 */
.star40pro {
  width: 40% !important;
} /* 별 두개 */
.star50pro {
  width: 50% !important;
} /* 별 두개 반 */
.star60pro {
  width: 60% !important;
} /* 별 세개 */
.star70pro {
  width: 70% !important;
} /* 별 세개 반 */
.star80pro {
  width: 80% !important;
} /* 별 네개 */
.star90pro {
  width: 90% !important;
} /* 별 네개 반 */
.star100pro {
  width: 100% !important;
} /* 별 다섯개 */
.detailTable {
  width: 100%;
  border-top: 1px solid #cdcdcd;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  margin-top: 19px;
}
.detailTable th {
  background: #f9f9f9;
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 5px 10px;
  text-align: left;
}
.detailTable td {
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
  padding: 5px 10px;
}
/*스펙*/
.specTable_wrap {
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 15px;
}
.specTable {
  width: 100%;
}
.specTable th {
  font-weight: bold;
  padding: 5px 10px;
  text-align: left;
}
.specTable td {
  padding: 5px 10px;
}
/*특장점*/
.sfe_wrap {
  text-align: center;
}
/*사용후기*/
.coment_wrap {
  padding: 15px 10px;
  border-bottom: 1px solid #cdcdcd;
  overflow: hidden;
}
.coment_TT {
  padding-bottom: 10px;
  padding-top: 5px;
  overflow: hidden;
  font-weight: bold;
  font-size: 15px;
}
.coment_dc {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  text-overflow: ellipsis;
}
/*전문가리뷰*/
.pro_review_imgWrap {
  float: left;
  width: 110px;
  height: 110px;
  padding: 3px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin-left: 5px;
  text-align: center;
  line-height: 110px;
}
.pro_review_imgSize {
  width: auto;
  height: auto;
  max-width: 110px;
  max-height: 110px;
}
.pro_review_dcWrap {
  overflow: hidden;
  margin-left: 145px;
}
.pro_review_dchead {
  padding: 15px 0px;
  padding-top: 10px;
  overflow: hidden;
}
.pro_review_dcTT {
  font-weight: bold;
  font-size: 18px;
  float: left;
}
.pro_review_more {
  float: right;
  font-size: 15px;
  cursor: pointer;
}
.pro_review_moreIcon {
  margin-right: 5px;
  font-size: 10px !important;
}
.pro_review_dc {
  overflow: hidden;
  white-space: normal;
  width: 100%;
  height: 35px;
  font-size: 15px;
}

/******* 리스트 테이블 영역 *******/
.listTable_wrap {
  overflow: hidden;
}

/* 리스트 테이블*/
.listTable_tt {
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif !important;
  padding-left: 5px;
  margin: 15px 0px !important;
}
.listTable {
  width: 100%;
  border-top: 2px solid #00427d;
  border-bottom: 1px solid #00427d; /* border-left:1px solid #cdcdcd; border-right:1px solid #cdcdcd; */
}
.listTable th {
  background: #f1f2f6;
  font-weight: bold; /* border-right:1px solid #cdcdcd; */
  border-bottom: 1px solid #cdcdcd;
  padding: 5px;
  text-align: center;
  line-height: 30px;
}
.listTable td {
  border-bottom: 1px solid #cdcdcd; /* border-right:1px solid #cdcdcd; */
  padding: 5px;
  text-align: center;
  line-height: 30px;
}
/* .listTable > tbody > tr:nth-of-type(odd) {background-color:#eeeeee;} */
/* .listTable > tbody > tr:nth-of-type(even) {background-color:#fcfcfc;} */
.listTable > thead > tr > th > span {
  padding: 0px !important;
}
.listTable > tbody > tr > td > span {
  padding: 0px !important;
}
.listTable > thead > tr > th > span > input[type="checkbox"] {
  border: 1px solid #bbb;
  background: #bbb;
}
.listTable > tbody > tr > td > span > input[type="checkbox"] {
  border: 1px solid #bbb;
  background: #bbb;
}
.listTable > thead > tr > th > span > svg {
  color: #bbb;
  width: 0.8em;
  height: 0.8em;
}
.listTable > tbody > tr > td > span > svg {
  color: #bbb;
  width: 0.8em;
  height: 0.8em;
}
.listTableNowrap {
  width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* 테이블 공통 컴포넌트 */
.star_deco {
  color: #ff0000;
  font-weight: bold;
}
.textLeft {
  text-align: left !important;
}
.textRight {
  text-align: right !important;
}
.tableBot_margin {
  margin-bottom: 20px;
}
.Bot_margin {
  margin-bottom: 10px;
}
.ui-datepicker-trigger {
  border: none;
  background: none;
  color: #0d4c99;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 3px;
  cursor: pointer;
} /* 데이터 피커 아이콘 여백 제거*/
.radiobtn_aline {
  vertical-align: middle;
  vertical-align: -2px;
}
.srch_chekbox_aline {
  vertical-align: middle;
  vertical-align: -2px;
  margin-left: 5px;
}
.dis[disabled] {
  background: #ebebe4;
}
.textarea_resizeNone {
  resize: none;
}
/* 페이지네이트 */
.pagination {
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.pagination ul {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
}
.pagination li {
  list-style-type: none;
  display: inline;
  padding-bottom: 1px;
}
.pagination a,
.pagination a:visited {
  padding: 5px 7px;
  border: 1px solid #bfbfbf;
  text-decoration: none;
  color: #494949;
}
.pagination a:hover,
.pagination a:active {
  border: 1px solid #00305c;
  color: #000;
  background-color: #cccccc;
}
.pagination a.currentpage {
  background-color: #2e5a99;
  color: #fff !important;
  border-color: #00305c;
  font-weight: bold;
  cursor: default;
}
.pagination a.disablelink,
.pagination a.disablelink:hover {
  background-color: white;
  cursor: default;
  color: #929292;
  border-color: #929292;
  font-weight: normal !important;
}
.pagination a.prevnext {
  font-weight: bold;
}
/*상품비교 플롯팅*/
.compare_choice_wrap {
  display: none;
  border-left: 2px solid #2e5a99;
  border-right: 2px solid #2e5a99;
  width: 800px;
  margin-left: -400px;
  left: 50%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.compare_choice_head {
  padding: 8px;
  background: #2e5a99;
  overflow: hidden;
}
.compare_choice_tt {
  float: left;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.compare_choice_close {
  float: right;
  color: #fff;
  font-size: 20px !important;
  cursor: pointer;
}

/******* 풋터 영역 *******/
.footer_wrap {
  background: #fbfcfe;
  border-top: 1px solid #d9e0e6;
  height: 100px;
  line-height: 40px;
  /* margin-top: 10px; */
}
.copyright {
  width: 1130px;
  margin: 0 auto;
}
.copyright_txt01 {
  color: #757575 !important;
  cursor: pointer;
}
/* .copyright_txt01 a{color:#757575; text-decoration:none;}
.copyright_txt01 a:link, a:visited, a:active {color:#757575; text-decoration:none;}
.copyright_txt01 a:hover {color:#757575; text-decoration:none;} */
.copyright_txt02 {
  color: #757575;
  padding-right: 10px;
  cursor: pointer;
}
.copyright_div {
  color: #d4d5d7;
  padding: 5px;
}
.consorlogos {
  float: right;
  margin-top: 10px;
}

/******* 공통 *******/

/*버튼 영역*/
.list_btn_wrap {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
} /* 리스트테이블의 버튼 영역 반응형시 필요*/
.btn_alignRight {
  text-align: right;
}
/* 버튼 */
.btn_sky {
  background: #2c93cf; /* Old browsers */
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 6px;
  display: inline;
  border: 1px solid #bfbfbf;
  cursor: pointer;
}
.btn_sky i {
  margin-right: 3px;
}
.btn_blue {
  background: #2d66ac; /* Old browsers */
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 6px;
  display: inline;
  border: 1px solid #00427d;
  cursor: pointer;
}
.btn_blue i {
  margin-right: 3px;
}
.btn_gray_wrap {
  width: 70px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
}
.btn_gray {
  background: #e9e9e9; /* Old browsers */
  color: #3f444e;
  font-size: 14px;
  font-weight: bold;
  padding: 1px 3px;
  height: 100%;
  border: 1px solid #00427d;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.waybtn_padChange {
  padding-left: 5px;
  padding-right: 3px;
}
.login_heiChange {
  height: 36px;
  line-height: 36px;
  margin-left: 4px;
  font-size: 12px;
}
.btn_dark {
  background: #656565; /* Old browsers */
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 4px;
  margin-left: 4px;
  border: 1px solid #3c3c3c;
  cursor: pointer;
  float: left;
  height: 34px;
  line-height: 34px;
}
.btn_srchblue_wrap {
  width: 28px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
}
.btn_srchblue_wrap_absol {
  width: 28px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 0px;
  top: 0px;
}
.btn_srchblue_wrap_wide {
  width: 60px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
}
.btn_srchblue {
  background: #2d66ac; /* Old browsers */
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 1px 7px;
  height: 100%;
  border: 1px solid #00427d;
  cursor: pointer;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.icon_btn {
  margin-right: 3px;
  color: #646464;
}
.icon_btn02 {
  display: inline-block;
  margin-right: 3px;
  color: #646464;
}
.spanbtn_navy {
  background: #2e5a99; /* Old browsers */
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 5px;
  border: 1px solid #00305c;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.spanbtn_blue {
  background: #356ec0; /* Old browsers */
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 5px;
  border: 1px solid #00305c;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.spanbtn_gray {
  background: #e9e9e9; /* Old browsers */
  color: #3f444e;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 5px;
  border: 1px solid #00427d;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* Div 좌측, 우측 정렬 */
.float_L {
  float: left;
}
.float_R {
  float: right;
}

/* 영역 보임, 숨김 */
.disBlock {
  display: block;
}
.disNone {
  display: none;
}

/* 좌우 길이 */
.wid20 {
  width: 20px !important;
}
.wid40 {
  width: 40px !important;
}
.wid50 {
  width: 50px !important;
}
.wid60 {
  width: 60px !important;
}
.wid70 {
  width: 70px !important;
}
.wid80 {
  width: 80px !important;
}
.wid100 {
  width: 100px !important;
}
.wid120 {
  width: 120px !important;
}
.wid150 {
  width: 150px !important;
}
.wid170 {
  width: 170px !important;
}
.wid180 {
  width: 180px !important;
}
.wid200 {
  width: 200px !important;
}
.wid250 {
  width: 250px !important;
}

/* 좌우 길이 */
.wid70per {
  width: 70% !important;
}
.wid75per {
  width: 75% !important;
}

/* 상하 길이 */
.hei150 {
  height: 150px !important;
}

/* 좌우 마진 길이 */
.margin_L3 {
  margin-left: 3px;
}

/* 폰트 */
.fontBold {
  font-weight: bold;
}

/* 디스플레이 상태 */
.dis_none {
  display: none !important;
}
.dis_blk {
  display: block !important;
}

.Container_layout {
  border: 1px solid #dae2e5;
  margin-top: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.position_rel {
  position: relative !important;
  margin-top: 10px;
}

.MuiToolbar-root {
  padding: 0px 5px !important;
  min-height: 40px !important;
  height: 40px !important;
}
/* .MuiOutlinedInput-root{margin:0 auto; background: #fff;} */
.MuiOutlinedInput-input {
  background: #fff !important;
  border: 0px !important;
}
.MuiTablePagination-spacer {
  -webkit-flex: none !important;
  flex: none !important;
}
.simplebar-content {
  position: relative;
}
.MuiTablePagination-root {
  position: absolute;
  right: 0px;
}
.tableSrchInput {
  width: 300px;
}
.tableSrchInput .MuiOutlinedInput-root {
  position: absolute !important;
  left: 0px !important;
  margin: none !important;
}
.tableSrchInput_Wrap {
  padding: 10px 0px !important;
}
.MuiToolbar-root {
  width: 400px;
}
.MuiPaper-root {
  position: relative;
}
.MuiTablePagination-toolbar {
  width: 400px;
}
.MuiTablePagination-actions {
  position: absolute;
  right: 0px;
}
.MuiTablePagination-displayedRows {
  position: absolute;
  right: 100px;
}
.MuiTablePagination-input {
  position: absolute !important;
  right: 150px;
}
.MuiTablePagination-selectLabel {
  position: absolute;
  right: 230px;
}
.ButtonStyle01 {
  float: right;
  background: #2c58b1 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
}
.ButtonStyle02 {
  background: #2c58b1 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
}
.ButtonStyle03 {
  background: #949494 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
}
.ButtonStyle04 {
  background: #22abcd !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
}
.ButtonStyle05 {
  background: #4e4e4e !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
}
.ButtonStyleDisabled {
  background: #f4f4f4 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
}

.MuiCard-root {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.glyThumnail {
  border: 1px solid #e7ebee;
  padding-top: 120px !important;
}
.glyThumnail img {
  left: 0;
}
.thumbnail_ttWrap {
  margin-top: 5px;
  padding: 0px !important;
  text-align: left;
  display: block !important;
}
.thumbnail_newIcon {
  font-size: 9px;
  background: #2c58b1;
  border-radius: 2px !important;
  padding: 0px !important;
  color: #fff !important;
  font-weight: bold;
  min-width: 15px !important;
  width: 15px !important;
  height: 15px !important;
  float: left;
  margin-top: 2px;
  margin-right: 2px;
}
.thumbnail_newIcon02 {
  font-size: 9px;
  background: #2c58b1;
  border-radius: 2px !important;
  padding: 0px !important;
  color: #fff !important;
  font-weight: bold;
  min-width: 15px !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 4px;
  font-weight: normal !important;
  font-size: 11px !important;
  position: relative;
  top: -2px;
}
.thumbnail_newIcon_ing {
  font-size: 9px;
  background: #22abcd;
  border-radius: 2px !important;
  padding: 0px !important;
  color: #fff !important;
  font-weight: bold;
  min-width: 15px !important;
  width: 40px !important;
  height: 15px !important;
  float: left;
  margin-top: 2px !important;
  margin-right: 2px;
}
.thumbnail_newIcon_f {
  font-size: 9px;
  background: #5c5c5c;
  border-radius: 2px !important;
  padding: 0px !important;
  color: #fff !important;
  font-weight: bold;
  min-width: 15px !important;
  width: 30px !important;
  height: 15px !important;
  float: left;
  margin-top: 2px !important;
  margin-right: 2px;
}
.thumbnail_name {
  font-weight: bold !important;
  letter-spacing: -0.5px !important;
  padding: 0 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbnail_date_wrap {
  margin-top: 0px !important;
  display: block !important;
}
.thumbnail_date {
  color: #757575;
  font-size: 14px !important;
  letter-spacing: -0.5px !important;
  display: inline-block;
  width: 100%;
  padding: 0px 2px;
}

.bbsInputSize input {
  padding: 5px 5px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Noto Sans KR", sans-serif !important;
}
.bbsInputSize select {
  padding: 5px 5px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Noto Sans KR", sans-serif !important;
}
.bbsInputSizeDisabled input {
  padding: 5px 5px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Noto Sans KR", sans-serif !important;
  background: #f4f4f4 !important;
}
.bbsTextarea {
  resize: none !important;
  font-size: 14px !important;
}
.GlassSize {
  font-size: 16px;
  position: relative;
  top: 5px;
  margin-top: 5px !important;
}
.glyPagePosition {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.glybbs2_Wrap {
  border: 1px solid #dae2e5;
}
.glybbs2_ImgWrap {
  padding-bottom: 10px !important;
}
.glybbs2_Img {
  width: 214.98px !important;
  height: 122px !important;
}
.glybbs2_name {
  font-weight: bold !important;
  letter-spacing: -0.5px !important;
  margin-top: 10px !important;
  cursor: pointer;
}
.glybbs2_date {
  color: #757575 !important;
  font-size: 14px !important;
  letter-spacing: -0.5px !important;
}
.glybbs2_dadanWrap {
  margin: 0px !important;
}
.glybbs2_dadan {
  height: 52px !important;
  line-height: 52px;
  width: 50% !important;
  display: block !important;
  float: left !important;
  padding: 0px !important;
  margin: 0px !important;
  text-align: center !important;
}
.glybbs2_dadan_tt {
  background: #f0f4f9;
}
.glybbs2_dadan_ttFont {
  font-weight: bold !important;
}

.bannerPop {
  background: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: 100000;
  display: none;
}
.bannerPop_ImgWrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 150000;
  display: none;
}
.bannerPop_Img {
  z-index: 200000;
  display: none;
  width: 1280px;
  height: 720px;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
}
.bannerPop_Img img {
  width: 1280px;
  height: 720px;
}
.bannerPop_menuWrap {
  position: absolute;
  top: 80px;
  right: 200px;
  height: 30px;
  z-index: 400000;
  font-size: 18px;
  font-weight: bold;
}
.bannerPop_menuWrap span {
  padding: 0px 2px;
  cursor: pointer;
  border-radius: 5px;
}
.bannerPop_logo {
  cursor: pointer;
  position: absolute;
  top: 72px;
  left: 210px;
  height: 30px;
  z-index: 400000;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
}
.bannerPop_Xbtn {
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  cursor: pointer;
  z-index: 300000;
}
/* input[type="file" i]{background:#ff0000;} */
/* #raised-button-file{background:#ff0000;} */

/* 회사소개 스타일  */
.corp_name {
  background: #f8f9fb;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 10px 0px;
  height: 30px;
  line-height: 25px;
  margin-top: 10px;
}
.corp_ttWrap {
  font-size: 16px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}
.corp_ttIcon {
  background: #2c58b1;
  padding: 0px 2px;
  margin-right: 10px;
}
.corp_summaryWrap {
  overflow: hidden;
}
.corp_summaryImg {
  display: inline-block;
  width: 43%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #bfc4c8;
  float: left;
  margin-right: 2%;
  height: 343px;
  line-height: 343px;
  text-align: center;
}
.corp_summaryTabWrap {
  overflow: hidden;
  display: inline-block;
  width: 55%;
  float: left;
}
.corp_historyWrap {
  padding: 5px 20px;
  font-size: 20px;
  background: #f8f9fb;
  border-left: 2px solid #c5c5c7;
  line-height: 22px;
  margin-bottom: 10px;
}
.corp_historyTT {
  display: block;
  font-weight: bold;
}
.corp_historyTable {
  margin: 10px;
}
.corp_productWrap {
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.corp_productImgWrap {
  background: #f8f9fb;
  margin-top: 5px;
  overflow: hidden;
  padding: 5px;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}
.corp_productTxt {
  display: block;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}
.corp_contactIcon {
  text-align: center;
}
.corp_contactTxt {
  font-size: 14px;
}

/* 빅데이터 페이지  */
.bigdataPage_leftWrap {
  overflow: hidden;
  float: left;
  position: relative;
}
.bigdataPage_RightWrap {
  overflow: hidden;
  float: right;
  position: relative;
}
.bigdataPage_absolWrap01 {
  background: #000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff !important;
}
.bigdataPage_absolWrap02 {
  background: #000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.bigdataTxt_Wrap01 {
  position: absolute !important;
  bottom: 170px;
  height: 38px;
  width: 100% !important;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 0px 20px;
  line-height: 25px;
  box-sizing: border-box;
  text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.5);
}
.bigdataTxt_Wrap02 {
  position: absolute !important;
  bottom: 170px;
  height: 38px;
  width: 100% !important;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 0px 20px;
  line-height: 25px;
  box-sizing: border-box;
  text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.5);
}
.bigdataTxt_Wrap03 {
  position: absolute !important;
  top: 230px;
  width: 100% !important;
  color: #fff;
  text-align: center;
  font-size: 22px;
  padding: 0px 20px;
  line-height: 30px;
  box-sizing: border-box;
  text-shadow: 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.5);
}
.bigdataBtn_Wrap {
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  margin-left: -93px !important;
  background: #000 !important;
  overflow: hidden;
  opacity: 0.5;
  height: 38px;
  width: 186px !important;
  border-radius: 5px;
}
.bigdataBtn_Wrap03 {
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  margin-left: -108.5px !important;
  background: #000 !important;
  overflow: hidden;
  opacity: 0.5;
  height: 38px;
  width: 217px !important;
  border-radius: 5px;
}
.bigdataBtn01 {
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  margin-left: -93px !important;
  width: 186px !important;
  color: #fff !important;
  padding: 6px 16px !important;
  box-shadow: none !important;
  border: 1px solid #fff !important;
  font-weight: bold !important;
}
.bigdataBtn02 {
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  margin-left: -93px !important;
  width: 186px !important;
  color: #fff !important;
  padding: 6px 16px !important;
  box-shadow: none !important;
  border: 1px solid #fff !important;
  font-weight: bold !important;
}
.bigdataBtn03 {
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  margin-left: -108.5px !important;
  width: 217px !important;
  color: #fff !important;
  padding: 6px 16px !important;
  box-shadow: none !important;
  border: 1px solid #fff !important;
  font-weight: bold !important;
}
/* 디자인 페이지들 스타 */
.common_totalWrap {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  /* margin-top: 17px; */
  padding: 0px 25px;
}
.common_totalWrap02 {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  /* margin-top: 17px; */
  text-align: center;
  padding: 10px 0px;
}
.common_totalWrap03 {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  /* margin-top: 17px; */
  text-align: center;
  padding-bottom: 30px;
}
.common_totalWrap04 {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  /* margin-top: 17px; */
  text-align: center;
  padding-bottom: 80px;
}
.common_totalWrap05 {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  margin-top: 17px;
  text-align: center;
  padding: 10px;
}
.common_totalWrap06 {
  background: #fafafa;
  border-top: 1px solid #e3e7e9;
  border-bottom: 1px solid #e3e7e9;
  overflow: hidden;
  margin-top: 17px;
  text-align: left;
  padding: 50px;
}
.common_ttWrap {
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-left: 5px;
}
.common_ttWrap02 {
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}
.common_ttWrap03 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-left: 5px;
  text-align: left;
  margin-left: 25px;
}
.common_ttIcon {
  background: #2c58b1;
  padding: 0px 2px;
  margin-right: 10px;
}
.common_ttIcon02 {
  color: #2c58b1;
  padding: 0px 2px;
  margin-right: 5px;
  font-size: 12px;
  position: relative;
  top: -2px;
}
.common_ttIcon3 {
  background: #2c58b1;
  padding: 0px 2px;
  margin-left: 80px;
  margin-right: 10px;
}
.common_txt {
  font-size: 18px;
  line-height: 25px;
  padding-left: 15px;
  color: #444;
}
.common_txt2 {
  font-size: 18px;
  line-height: 25px;
  padding-left: 15px;
  display: inline-block;
  color: #444;
}
.common_txt3 {
  font-size: 18px;
  line-height: 25px;
  padding-left: 15px;
  display: inline-block;
  color: #444;
  margin-left: 100px;
}
.common_txt02 {
  font-size: 14px;
  line-height: 30px;
  color: #444;
  text-align: left;
  margin: 0px 25px;
}
.image-scroll {
  display: inline-block;
  height: auto;
  width: "1250px";
}

.common_img {
  padding: 15px;
  background: #fff;
  overflow: hidden;
  text-align: center;
  border: 1px solid #f1f3f4;
  border-radius: 10px;
  margin-bottom: 20px;
}
.common_img02 {
  padding: 15px;
  background: #fff;
  overflow: hidden;
  text-align: center;
  border: 1px solid #f1f3f4;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 25px;
  margin-right: 25px;
}
.common_txtAlign {
  overflow: hidden;
  text-align: left;
}
.common_imgTT {
  background: #0065ce;
  position: absolute;
  top: 50px;
  left: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.1);
}
.common_imgTT02 {
  background: #0065ce;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
.logisticsCouncil_Wrap {
  margin-bottom: 60px;
  background: url(../images/logistic_img.png) 0px 0px;
  width: 770px;
  height: 663px;
  display: inline-block;
  position: relative;
}
.logisticsCouncil_txt01 {
  margin-top: 90px;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.logisticsCouncil_txt02 {
  font-size: 16px;
  position: absolute;
  top: 212px;
  right: 220px;
}
.logisticsCouncil_txt03 {
  font-size: 16px;
  position: absolute;
  top: 487px;
  left: 73px;
}
.logisticsCouncil_txt04 {
  font-size: 16px;
  position: absolute;
  top: 487px;
  right: 72px;
}
.logisticsCouncil_txtblue {
  color: #21a1ef;
}

/* 팝업  */
.pop_totalWrap {
  overflow: hidden;
  padding: 15px;
  text-align: center;
}
.pop_ttWrap {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
  padding-left: 5px;
  text-align: left;
}
.pop_ttIcon {
  background: #2c58b1;
  padding: 0px 2px;
  margin-right: 10px;
}

/* 견적문의   */
.accordionWrap {
  text-align: center !important;
  background: #0065ce !important;
}
.accordiontt_display {
  display: inline-block !important;
}
.PriceQna_tab {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: bold !important;
  color: #6a6a6a !important;
}
.PriceQna_tab.Mui-selected {
  color: #0065ce !important;
}
.PriceQnaWrap {
  background: #fff;
}
.PriceQnaWrap div.css-19kzrtu {
  padding: 5px;
}
.PriceQnaWrap label {
  margin-top: 4px;
  display: inline-block;
}
.PriceQnaChkBox {
  position: relative;
  top: 2px;
  left: -2px;
}

/* 댓글 */
.comment_TotalWrap {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0px;
  width: 100%;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.recomment_icon {
  display: table-cell;
  color: #777;
  padding-left: 10px;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 5px;
}
.comment_contentsWrap {
  display: table-cell;
  padding-left: 10px;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.comment_contentsWrap div {
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  padding: 3px;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.commenterNm {
  font-weight: bold;
}
.commenterDD {
  font-weight: normal;
  color: #aaa;
  font-size: 12px !important;
}
.comment_BtnWrap {
  overflow: hidden;
  width: 100%;
}
.commentBtn {
  border: 1px solid #ddd !important;
  background: #fff !important;
  padding: 2px 6px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #333 !important;
  margin-left: 5px !important;
}
.commentBtn02 {
  background: #2c58b1 !important; /* padding:6px 16px !important; */
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100%;
  height: 56px;
}
.commentBtn03 {
  background: #eee !important; /* padding:6px 16px !important; */
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  width: 100%;
  height: 56px;
}
.commentBtn_LmarginX {
  margin-left: 0px !important;
}
.recomment_Wrap {
  border-bottom: 1px solid #e0e0e0;
  background: #f8f9fb;
}
/* .recomment_InputWrap{border-bottom:1px solid #e0e0e0;} */

/* 가입약관 동의  */
.agreeTt_Wrap {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.agreeTt_Wrap02 {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0px;
  display: inline-block;
  position: relative;
  top: 2px;
  padding-left: 5px;
}
.agreeTt_Wrap03 {
  font-size: 14px;
  width: 450px;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
  position: relative;
  top: 10px;
}
.agreeTotal_Wrap {
  width: 500px;
  margin: 0 auto;
}
.agreeTxt_Wrap {
  padding: 0px 15px;
  width: 450px;
  height: 130px;
  white-space: pre-wrap;
  margin: 0 auto;
  overflow-y: auto;
  border: 1px solid #eee;
}
.termsNprivacy pre {
  width: 100%;
  box-sizing: border-box;
  height: 600px;
  background: #fff;
  text-align: left;
}
.agreeBtn_Wrap {
  text-align: center;
  padding: 10px;
}

.NoteSection_L {
  width: 18%;
  margin-right: 1%;
  float: left;
  border: 1px solid #dae2e5;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
}
.NoteSection_R {
  width: 81%;
  float: left;
}
.NoteleftmenuTT {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  background: #f1f1f1;
  display: inline-block;
  width: 100%;
  padding: 20px 0px;
}
.Noteleftmenu_btn01 {
  background: #2c58b1;
  border: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  letter-spacing: -1px;
  font-weight: bold;
  padding: 10px 0px;
  font-size: 14px;
  text-align: center;
  width: 50%;
  border-right: 1px solid #fff;
}
.Noteleftmenu_btn02 {
  background: #2c58b1;
  border: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  letter-spacing: -1px;
  font-weight: bold;
  padding: 10px 0px;
  font-size: 14px;
  text-align: center;
  width: 50%;
}
.Noteleftmenu li {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 0px;
  border-top: 1px solid #dae2e5;
  cursor: pointer;
  padding-left: 10px;
}
.Noteleftmenu li.paddingR {
  padding-right: 10px;
}
.NoteleftmenuSpan {
  float: right;
  padding-right: 10px;
  font-size: 10px;
  color: #aaa;
}
.Noteleftmenu li:hover {
  background: #f6f8ff;
  color: #2964e0;
}
.NoteTable {
  width: 100%;
}
.NoteTable td {
  border: 0px;
  padding: 0px;
  line-height: initial;
}
.NoteTable td button {
  height: 30.12px;
}
.NoteDetail_txtSection {
  height: 400px;
  vertical-align: top;
  white-space: pre;
}
.NoteWrite_txtSection {
  height: 400px;
  resize: none;
}
.NoteTable_head tr th:nth-child(2) {
  width: 585px;
}
.NoteTable_tdWidth div {
  width: 585px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NoteTable_Nosee div {
  width: 585px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #0067dd;
  font-weight: bold;
}
.NoteTable_Nosee div h6 {
  font-weight: bold;
}
.NoteBtn_margin {
  margin-left: 5px !important;
}
.NoteNoSrch_td div {
  box-shadow: none;
}
.NoteNoSrch_wrap {
  margin-bottom: 0px !important;
}
.NoteInputSize input {
  padding: 5px 5px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Noto Sans KR", sans-serif !important;
  white-space: pre;
}
.NoteAllow_Btn {
  background: #5c5c5c !important; /* padding:6px 16px !important; */
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100%;
  padding: 0px !important;
}
.NoteAllow_bluBtn {
  background: #2c58b1 !important; /* padding:6px 16px !important; */
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100%;
  padding: 0px !important;
}
.NoteTradeListTd {
  display: table-cell !important;
  text-align: left;
}
.NoteTradeListImgWrap {
  display: table-cell;
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  overflow: hidden;
  vertical-align: middle;
}
.NoteTradeListImgWrap img {
  width: 40px;
}
.NoteTradeListTTWrap {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
}

.adresSectionWrap {
  border: 1px solid #e3e7e9;
  overflow: hidden;
}
.adresTTWrap {
  background: #fafafa;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #e3e7e9;
}
.adresMenuWrap {
  width: 26%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 500px;
  box-sizing: border-box;
}
.adresSelectWrap01 {
  width: 35%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 500px;
  box-sizing: border-box;
  position: relative;
}
.adresSelectWrap02 {
  width: 4%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 500px;
  box-sizing: border-box;
}
.adresSelectWrap03 {
  width: 35%;
  float: left;
}
.adresMenu {
  margin: 15px;
  text-align: left;
}
.adresMenu li {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
  list-style: disc;
  list-style-position: inside;
}
.adresMenu li:hover {
  font-weight: bold;
}
.adresMenu li span.adresSect_num {
  font-size: 13px;
  font-weight: normal;
  color: #2c58b1;
}
.adresSection01,
.adresSection02,
.adresSection03,
.adresSection04 {
  width: 90%;
  margin-left: 5.5%;
  margin-top: 15px;
}
.adresSectionTT {
  font-size: 14px;
  font-weight: bold;
}
.adresDBlebtn01,
.adresDBlebtn02,
.adresDBlebtn03,
.adresDBlebtn04 {
  overflow: auto;
  height: 445px;
  margin-top: 5px;
}
.adresbtn {
  font-weight: bold;
  color: #2c58b1;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff;
  padding: 4px;
  cursor: pointer;
}
.adres_Rbtn {
  position: absolute;
  right: -28px;
  top: 230px;
}
.adres_Lbtn {
  position: absolute;
  right: -28px;
  top: 260px;
}
.adresBtnWrap {
  border: 1px solid #e3e7e9;
  border-top: 0px;
  overflow: hidden;
}
.adresBtnWrapTxt {
  color: #bbb;
  padding: 5px 0px;
  padding-left: 10px;
  display: block;
}
.adresBtnSection {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}
.adresBtn_Lmargin {
  margin-left: 5px !important;
}
.adresPop_OpenBtn {
  background: #5c5c5c !important; /* padding:6px 16px !important; */
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100%;
  padding: 0px !important;
}

/* 이벤트 */
.eventSectionWrap {
  border: 1px solid #e3e7e9;
  overflow: hidden;
  height: 600px;
}
.eventSelectWrap01 {
  width: 14%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 600px;
  box-sizing: border-box;
  position: relative;
}
.eventSelectWrap02 {
  width: 12%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 600px;
  box-sizing: border-box;
}
.eventSelectWrap03 {
  width: 15%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 560px;
  box-sizing: border-box;
}
.eventSelectWrap04 {
  width: 39%;
  float: left;
}
.eventMenuWrap {
  width: 20%;
  border-right: 1px solid #e3e7e9;
  float: left;
  height: 600px;
  box-sizing: border-box;
}
.eventSection {
  overflow: auto;
  white-space: nowrap;
  height: 500px;
}
.eventSection2 {
  overflow: auto;
  white-space: nowrap;
}
.attendInfo {
  margin: 15px;
  text-align: left;
  height: 330px;
}
.attendInfo li {
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
  list-style-position: inside;
  margin-bottom: 15px;
}
.attendInfo li span {
  margin-left: 5px;
}
.attendInfo li.no-availability {
  text-decoration-line: line-through;
  opacity: 0.5; /* 투명도를 낮춤 */
  pointer-events: none; /* 클릭을 비활성화 */
}
.EvnetButton {
  margin-top: 55px;
  text-align: center;
}
.eventSectionTT {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #5b5e5f;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.eventMenu {
  margin: 15px;
  text-align: left;
}
.eventMenu li {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
  list-style-position: inside;
  margin-bottom: 15px;
}
.cancelEventButton01 {
  background: #2c58b1 !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
}
.cancelEventButton02 {
  pointer-events: none;
}
.eventTable {
  width: 100%;
  border-top: 2px solid #2e5a99;
  font-family: "Noto Sans KR", sans-serif !important;
}
.eventTable th {
  font-size: 14px;
  background: #f1f2f6;
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
  padding: 8px;
  text-align: left;
  line-height: 30px;
  box-sizing: border-box;
}
.eventTable td {
  font-size: 14px;
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
  padding: 5px;
  line-height: 30px;
  box-sizing: border-box;
}

/* 본인인증 */
.pwCertification {
  text-align: center;
  margin-top: 100px;
}
.pwChange {
  text-align: center;
  margin-top: 90px;
}
.certificationSection {
  display: inline-block;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.pwSpan1 {
  margin-right: 45px;
  font-size: 15px;
  color: #a9a9a9;
}
.pwSpan2 {
  margin-right: 30px;
  font-size: 15px;
  color: #a9a9a9;
}
.pwInput1 {
  border: none;
  outline: none;
  width: 403px;
  font-size: 15px;
  height: 30px;
}
.pwInput2 {
  font-size: 15px;
  height: 50px;
  width: 400px;
  border-radius: 10px;
  text-indent: 2em;
}
.pwInput2:hover {
  background: rgb(242, 244, 249);
}
.certificationSection ul li {
  float: left;
  height: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e3e7e9;
}
.certificationSection ul li:nth-child(even) {
  padding-bottom: 30px;
}
.changePwSection {
  display: inline-block;
  width: 570px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.nowPw {
  margin-right: 45px;
  font-size: 15px;
  color: #a9a9a9;
}
.newPw {
  margin-right: 59px;
  font-size: 15px;
  color: #a9a9a9;
}
.checkNewPw {
  margin-right: 28px;
  font-size: 15px;
  color: #a9a9a9;
}
.changePwSection ul li {
  float: left;
  height: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e3e7e9;
}
.changePwSection ul li:nth-child(odd) {
  padding-bottom: 30px;
}
.changePwSection ul li:nth-child(2) {
  border: none;
  padding-bottom: 30px;
}
.changePwButton {
  background: #2c58b1 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}
.resetPwButton {
  background: #949494 !important;
  padding: 6px 16px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #fff !important;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}
.passwordMsg {
  display: inline-block;
  width: 350px;
  text-align: left;
  margin-left: 90px;
}

.tradeThumnail {
  border: 1px solid #e7ebee;
  padding-top: 120px !important;
}
.tradeThumnail_Img {
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.tradePrice {
  font-weight: bold !important;
  font-size: 16px !important;
  color: #2c58b1 !important;
  padding-left: 0px !important;
  letter-spacing: -1px;
}
.tradeHeart_Img {
  width: 14px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.tradeHeart_Img02 {
  width: 20px;
  margin-left: 10px;
  float: right;
  margin-top: 5px;
}
.tradeSubmitDate {
  float: right;
  letter-spacing: -1px;
  width: initial !important;
  position: relative;
  top: -1.5px;
  padding-right: 5px;
}
.tradeDateInput {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  width: 100%;
  margin-right: 5px;
  font-family: "Noto Sans KR", sans-serif !important;
}
.tradeDatePicBg {
  height: 21.5px;
  line-height: 21.5px;
  box-sizing: border-box;
  font-weight: normal !important;
  position: relative;
  top: 1px;
  text-align: center;
  cursor: pointer;
}
.tradeDatePicBg02 {
  font-size: 14px !important;
  height: 30.13px;
  line-height: 28px;
  box-sizing: border-box;
  font-weight: normal !important;
  position: relative;
  top: 1px;
  text-align: center;
}
.tradeDoneChkbox {
  float: right;
  color: #777;
}
.tradeDoneChkbox input {
  position: relative;
  top: 2px;
  margin-right: 4px;
}
.tradeBottomMargin {
  margin-bottom: 20px;
}
.tradePaginationWrap {
  display: block;
  overflow: hidden;
}
.tradePaginationWrap nav {
  float: right;
}
.tradeTableTopLine_X {
  border-top: 1px solid #cdcdcd;
}
.tradeTableTopLine_X th {
  line-height: initial;
}
.tradeWriteImgWrapTh {
  text-align: center !important;
  line-height: initial !important;
}
.tradeWriteImgSpan {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e7ebee;
  width: 110px;
  height: 100px;
  text-align: center;
  background: #f9f9f9;
}
.tradeWriteImgWrap {
  text-align: left;
  line-height: initial !important;
}
.tradeWriteImgWrap span {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e7ebee;
  width: 110px;
  height: 100px;
  margin-left: 10px;
  text-align: center;
  overflow: hidden;
  position: relative;
  line-height: 100px;
}
.tradeWriteImgWrap span img {
  width: 100px;
}
.tradeWriteImgWrap span span {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #aaa;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.tradeCameraImg {
  opacity: 0.3;
  margin-top: 15px;
  margin-bottom: 10px;
}
.tradeBluBtn {
  width: 80%;
}
.tradeDetailCarouselWrap {
  text-align: center;
}
.tradeDetailCarouselWrap td {
  height: 500px;
  vertical-align: middle;
}
.tradeDetailTT td {
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  text-align: left;
}
.tradeDetailDataWrap {
  display: block;
  font-weight: normal;
  font-size: 13px;
  color: #888;
}
.tradeDetailDataWrap02 {
  display: block;
  font-weight: normal;
  font-size: 17px;
  color: #222;
  margin: 20px 0px;
  padding: 10px;
}
.tradeDetailDataWrap {
  display: block;
  font-weight: normal;
  font-size: 15px;
  color: #888;
}
.tradeDetailAmountWrap {
  display: block;
  font-size: 18px;
}
/* [class |= makeStyles-root]{position: relative; height:500px; border:1px solid #eee;}
[class |= makeStyles-itemWrapper]{height:500px; line-height: 470px;}
[class |= makeStyles-indicators]{position: absolute; bottom:0px;}
[class |= makeStyles-item] img{max-width: 770px; max-height:450px;} */

.carouselControl {
  position: relative;
  height: 500px;
  border: 1px solid #eee;
}
.carouselControl > div:first-child {
  height: 500px;
  line-height: 470px;
}
.carouselControl > div:last-child {
  position: absolute;
  bottom: 0px;
}
.carouselImg {
  max-width: 770px;
  max-height: 450px;
}

.addView_Btn {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  z-index: 1000;
  cursor: pointer;
}

.overflow_control {
  overflow: initial !important;
}

.MainThumNosrch {
  text-align: center;
  padding: 94px 0px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
}

.fc-daygrid-event {
  white-space: initial;
}

/* .slick-prev:before,
.slick-next:before {
  color: red;
  width: 12px;
  height: 12px;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
  opacity: 0;
  display: none;
}
.slick-arrow {
  right: 3% !important;
  z-index: 1;
  opacity: 0;
  display: none;
}

.slick-prev:before,
.slick-next:before {
  display: none;
} */

.custom-arrow {
  width: 40px; /* 원하는 버튼 크기 */
  height: 40px; /* 원하는 버튼 크기 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  /* border-radius: 50%; 원형 버튼 만들기 */
  z-index: 1; /* 슬라이더 요소 위에 오도록 설정 */
}
.custom-arrow img {
  width: auto; /* 원하는 너비 */
  height: 70px; /* 비율 유지 */
  max-width: 100%; /* 부모 요소의 너비에 맞게 조절 */
}

.next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
